<template>
  <div :class="computedClasses">
    <span
      v-if="!noIcon"
      :class="['icon', formattedIconVariant]"
    >
      <slot name="icon">
        <Fa :icon="[formattedFaCollection, formattedIcon]" />
      </slot>
    </span>
    <span>
      <strong
        v-if="title"
        class="title"
      >
        {{ title }}
      </strong>
      <slot />
    </span>

    <button
      v-if="isDismissible"
      class="dimiss-button"
      @click="emit('dismissed')"
    >
      <Fa icon="xmark" />
    </button>
  </div>
</template>

<script setup lang="ts">
import type { TipboxSize, TipboxVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  variant: TipboxVariant
  noBackground?: boolean
  isDismissible?: boolean
  noIcon?: boolean
  faCollection?: string
  faIcon?: string
  title?: string
  size?: TipboxSize
}>(), {
  faCollection: 'fad',
  size: 'md',
})

const emit = defineEmits<{
  dismissed: []
}>()

const formattedIconVariant = computed(() => `icon-${props.variant}`)
const formattedFaCollection = computed(() => props.faCollection || 'fad')

const IconVariantMap: Record<TipboxVariant, string> = {
  primary: 'circle-info',
  highlight: 'circle-info',
  warning: 'triangle-exclamation',
  danger: 'circle-exclamation',
  success: 'circle-check',
}
const formattedIcon = computed(() => props.faIcon || IconVariantMap[props.variant])

const computedClasses = computed(() => [
  'ztipbox',
  `ztipbox-${props.size}`,
  props.noBackground ? 'ztipbox-clear' : `ztipbox-${props.variant}`,
  {
    'with-title': props.title,
    'has-dismiss': props.isDismissible,
    'no-icon': props.noIcon,
  },
])
</script>
